.top-tabs {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 10vh;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  padding-left: 2vw;
  .top-flex {
    display: flex;
    align-items: center;
    .top-logo {
      width: 38px;
      height: 27px;
    }
    .logo-name {
      font-weight: bold;
      font-size: 1.5rem;
      color: #036eb8;
      margin-left: 14px;
    }
  }
  .active {
    color: #083865;
    font-weight: bold;
  }
  .divBlack {
    color: #666666;
  }
  div {
    margin-right: 3vw;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.2rem;
  }
}

.home-one {
  position: relative;
}
.m-bg-top-img {
  height: 700px;
  background-image: url('@/assets/m/了解详情背景.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.m-bg-top-img .lockimg {
  width: 300px;
  margin-top: 35%;
  margin-left: 20%;
}
.cent-text {
  position: absolute;
  bottom: 0;
  margin: 0 30px;
  margin-bottom: 3vh;
}
.cent-text .img-text {
  font-weight: 500;
  font-size: 2.2rem;
  color: #ffffff;
}
.cent-text .desc {
  font-weight: 400;
  font-size: 1.2rem;
  color: #ffffff;
  margin-top: 10%;
}
.cent-text .zlh-m {
  font-weight: 400;
  font-size: 1rem;
  color: #ffffff;
  margin-top: 10%;
}
.m-bg-two-img {
  background-image: url('@/assets/m/参数背景.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 30px;
}
.m-bg-two-img .lockimg2 {
  height: 500px;
  margin-left: -14%;
  margin-top: -10%;
}
.m-bg-three-img {
  /* height: 1700px; */
  background-image: url('@/assets/m/领域背景.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 30px;
}
/* .m-home-two-result {
  height: 686px;
  background-image: url('@/assets/m/resulttwo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
} */

.m-right-ctn-four {
  padding: 20px 30px 0 30px;
}
.m-right-ctn-four .en-title {
  font-size: 1rem;
  color: #8d97a1;
}
.m-right-ctn-four .title {
  font-weight: 500;
  font-size: 2rem;
  color: #083865;
  margin-top: 5px;
}
.m-right-ctn-four .title-line {
  margin-top: 12px;
  width: 263px;
  height: 8px;
  background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
}
.m-right-ctn-four .ctn {
  font-weight: 400;
  font-size: 1.2rem;
  color: #333333;
  margin-top: 4vh;
  width: 100%;
  margin-bottom: 30px;
  line-height: 30px;
}
.m-right-ctn-four .disflex {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.m-right-ctn-four .result-js {
  background-image: url('@/assets/home/CNAS@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 311px;
  height: 211px;
}
.m-right-ctn-four .right-card {
  width: 604px;
  height: 149px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px rgba(229, 240, 243, 0.3);
  border-radius: 4px;
  margin-top: 159px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.m-bg-three-img .en-title {
  font-size: 1rem;
  color: #8d97a1;
}
.m-bg-three-img .title {
  font-weight: 500;
  font-size: 2rem;
  color: #083865;
  margin-top: 5px;
}
.m-bg-three-img .title-line {
  margin-top: 12px;
  width: 263px;
  height: 8px;
  background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
}

.m-bg-three-img .accordion {
  margin-top: 50px;
}
.m-bg-three-img .leftBtn {
  position: absolute;
  right: 0;
  top: 0;
  width: 52px;
  height: 52px;
  z-index: 1;
  transform: rotateZ(90deg);
  margin-right: 40%;
  margin-top: -8%;
}
.m-bg-three-img .rightBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 52px;
  height: 52px;
  margin-right: 40%;
  margin-bottom: -8%;
  transform: rotateZ(90deg);
}
.m-bg-three-img .accordion-item {
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}
.m-bg-three-img .accordion-item .default-image {
  display: block;
  width: 100%;
  height: 137px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.m-bg-three-img .accordion-item .default-image .d-title-ctn {
  padding-left: 35px;
  padding-top: 20px;
}
.m-bg-three-img .accordion-item .default-image .d-title-ctn .d-enTitle {
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}
.m-bg-three-img .accordion-item .default-image .d-title-ctn .d-cnTitle {
  font-weight: 500;
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 10px;
}

.m-bg-three-img .accordion-item .hover-image {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 50px 20px;
  box-sizing: border-box;
}
.m-bg-three-img .accordion-item .hover-image .ctn-desc {
  font-weight: 500;
  font-size: 1.1rem;
  color: #ffffff;
  line-height: 30px;
  margin: 30px 0;
}
.m-bg-three-img .accordion-item .hover-image .title-ctn {
  padding-top: 1vh;
  padding-left: 5vw;
}
.m-bg-three-img .accordion-item .hover-image .title-ctn .enTitle {
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
}
.m-bg-three-img .accordion-item .hover-image .title-ctn .cnTitle {
  font-weight: 500;
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 10px;
}

/* 默认第一个项展开并显示 hover-image */
.accordion-item.active {
  min-height: 200px;
  height: auto;
}

/* 展开状态下隐藏 default-image，显示 hover-image */
.accordion-item.active .default-image {
  display: none;
}

.accordion-item.active .hover-image {
  display: block;
  width: 100%;
}

/* 非悬停项收缩 */
.accordion-item:not(.active) {
  min-height: 137px;
}

.smallIcon {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 39px;
  width: 39px;
  margin-bottom: 15px;
  margin-left: 40px;
}

.m-home-two-result .img-ctn {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 6vw;
  margin-top: 8vh;
}
.m-home-two-result .img-ctn .title {
  font-weight: 500;
  font-size: 2rem;
  color: #333333;
}
.m-home-two-result .img-ctn .ctn {
  font-weight: 400;
  font-size: 1rem;
  color: #333333;
  margin-top: 4vh;
}
.m-home-two-result .download-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 6vw;
  margin-bottom: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-home-two-result .download-btn .logoimg {
  width: 40px;
  height: 40px;
  margin-bottom: 24px;
}
.m-home-two-result .download-btn .down-btn {
  padding: 6px 12px;
  color: #ffffff;
  background: linear-gradient(134deg, #61c6ff 0%, #3278ff 100%), #1549d0;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
}

.m-cp-text {
  font-weight: 400;
  font-size: 1.2rem;
  color: #b0b9be;
  width: 100%;
}
.m-check {
  padding: 10px;
  min-width: 43%;
  background-image: url('@/assets/home/selectchanpin.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
}
.m-nocheck {
  padding: 10px;
  width: 42%;
  border-left: 4px solid #add6ff;
}
.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.m-rightBottomText {
  margin-top: 3vh;
}
.m-rightBottomText .m-title {
  font-weight: bold;
  font-size: 1.7rem;
  color: #083865;
}
.m-rightBottomText .m-desc {
  margin-top: 20px;
  font-weight: 400;
  font-size: 1.3rem;
  color: #666666;
  line-height: 25px;
}
.m-rightImg {
  width: 100%;
  margin-top: 50px;
}

.m-daping {
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  background-color: #ffffff;
  margin-top: 30px;
}
.m-daping .dapingImg {
  width: 90%;
}
.m-daping .title {
  font-weight: bold;
  font-size: 1.7rem;
  color: #083865;
  margin-top: 30px;
  text-align: center;
}
.m-daping .desc {
  margin-top: 20px;
  font-weight: 400;
  font-size: 1.3rem;
  color: #666666;
  line-height: 30px;
  text-align: center;
}
.m-jm-card {
  display: flex;
  align-items: center;
  background-color: #f0fafd;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.m-jm-card .title {
  font-weight: bold;
  font-size: 1.4rem;
  color: #083865;
}
.m-jm-card .desc {
  font-weight: 400;
  font-size: 1rem;
  color: #666666;
  margin-top: 12px;
  width: 80%;
  line-height: 30px;
}
.m-jm-card .imgstyle {
  width: 30px;
}

.m-home-four-result {
  position: relative;
  background-image: url('@/assets/home/result/fourbg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 30px;
}

.m-home-four-result .en-title {
  font-size: 1rem;
  color: #8d97a1;
}
.m-home-four-result .title {
  font-weight: 500;
  font-size: 2rem;
  color: #333333;
  margin-top: 5px;
}
.m-home-four-result .title-line {
  margin-top: 12px;
  width: 263px;
  height: 8px;
  background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
}
.m-home-four-result .bannerImg {
  width: 90% !important;
  margin: 30px 5%;
}

.m-home-four-result .ant-carousel .slick-prev {
  width: 30px;
  height: 30px;
}
.m-home-four-result .ant-carousel .slick-next {
  width: 30px;
  height: 30px;
}

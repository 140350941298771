.ant-carousel .cc .slick-dots li.slick-active button {
  background-color: #1549d0;
  width: 32px;
  height: 12px;
  border-radius: 41px;
}
.ant-carousel .cc .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #92969f;
}
.ant-carousel .cc .slick-dots li {
  margin-right: 24px;
}
.ant-carousel .cc .slick-dots {
  margin-bottom: 10vh;
}
.adm-floating-bubble-button {
  background: linear-gradient(134deg, #61c6ff 0%, #3278ff 100%), #1549d0;
  box-shadow: 0px 4px 4px 0px rgba(146, 201, 255, 0.2);
}

.m-home-one {
  position: relative;
}
.m-home-one .carousel-img {
  width: 100vw;
  height: 90vh;
  background-image: url('@/assets/m/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}
.m-home-one .m-left-ctn {
  position: absolute;
  top: 0;
  margin-top: 25vh;
  margin-left: 6vw;
}
.m-home-one .m-left-ctn .title {
  font-weight: bold;
  font-size: 2.2rem;
  color: #083865;
  margin-bottom: 16px;
}
.m-home-one .m-left-ctn .ctn {
  font-weight: 400;
  font-size: 1.2rem;
  color: #083865;
  margin-bottom: 50px;
}
.m-home-one .m-left-ctn .btn {
  width: 96px;
  height: 33px;
  background: #ffffff;
  font-weight: 500;
  font-size: 1.2rem;
  color: #083865;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.m-home-two {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  margin-bottom: 8vh;
}
.m-home-two .title {
  font-weight: bold;
  font-size: 1.7rem;
  color: #333333;
  margin-top: 8vh;
}
.m-home-two .look-all {
  font-weight: 400;
  font-size: 1rem;
  color: #1549d0;
  margin-top: 3vh;
  cursor: pointer;
}
.m-home-two .flex-row {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
  /* align-items: center; */
}

.m-home-two .flex-row .left-card {
  height: 122px;
  background: linear-gradient(180deg, #f2f8fa 0%, #edf9fc 100%);
  box-shadow: 0px 2px 3px 0px rgba(224, 231, 235, 0.2);
  border-radius: 12px;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: 16px;
  padding: 16px 16px 0 16px;
}
.m-home-two .flex-row .card-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #333333;
}
.m-home-two .flex-row .card-ctn {
  font-weight: 400;
  font-size: 1rem;
  color: #666666;
  margin-top: 8px;
  max-width: 50vw;
  z-index: 2;
}
.m-home-two .flex-row .card-img {
  width: 126px;
  height: 86px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 6px;
  margin-right: 1vw;
  transition: transform 0.1s;
  z-index: 1;
}

.m-home-two .flex-row .left-right {
  height: 176px;
  max-width: 49%;
  min-width: 49%;
  background: linear-gradient(180deg, #f2f8fa 0%, #edf9fc 100%);
  box-shadow: 0px 2px 3px 0px rgba(224, 231, 235, 0.2);
  border-radius: 12px;
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
}
.m-home-two .flex-row .left-right .card-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #333333;
  margin: 16px 0 0 16px;
}
.m-home-two .flex-row .left-right .card-ctn {
  font-weight: 400;
  font-size: 1rem;
  color: #666666;
  margin: 8px 16px 0 16px;
  /* max-width: 60%; */
  z-index: 2;
}
.m-home-two .flex-row .left-right .card-img {
  width: 74px;
  height: 74px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 3px;
}
.bt-ctn {
  width: 100vw;
  height: 25vh;
  background-image: url('@/assets/home/bt.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
}
.bt-ctn .logo-white {
  width: 44px;
  height: 42px;
  margin-bottom: 16px;
}
.bt-ctn .logo-text {
  font-weight: 500;
  font-size: 1.4rem;
  color: #ffffff;
}
.m-bt-ctn-home {
  width: 100vw;
  padding: 50px 30px;
  background-image: url('@/assets/home/home-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.m-bt-ctn-home .en-title {
  font-size: 1.2rem;
  color: #ffffff;
}
.m-bt-ctn-home .cn-title {
  font-weight: 500;
  font-size: 2.2rem;
  color: #ffffff;
  margin-top: 15px;
}
.m-bt-ctn-home .desc {
  font-weight: 400;
  font-size: 1.1rem;
  color: #ffffff;
  text-align: center;
  margin-top: 44px;
  line-height: 20px;
}
.m-line-view {
  background-image: url('@/assets/home/m-line.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  width: 100%;
  margin-top: 60px;
  position: relative;
}
.m-line-view .leftone {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 5%;
  margin-top: 3%;
}
.m-line-view .rightone {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 5%;
  margin-top: 3%;
}
.m-line-view .bottomone {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 12%;
  margin-top: 57%;
}
.m-line-view .bottomtwo {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 12%;
  margin-top: 57%;
}
.m-line-view .one {
  font-weight: 500;
  font-size: 1.4rem;
  color: #ffffff;
  margin-bottom: 15px;
}
.m-line-view .three {
  font-weight: 500;
  font-size: 1.1rem;
  color: #ffffff;
  margin-top: 40px;
}

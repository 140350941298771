.m-about-bg-top-img {
  width: 100vw;
  height: 280px;
  background-image: url('@/assets/home/aboutus/aboutus-top.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.en-text-m {
  position: absolute;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 11vh;
  margin-left: 6vw;
}
.imgs-text-m {
  position: absolute;
  font-weight: 500;
  font-size: 1.6rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 6vh;
  margin-left: 6vw;
}

.about-bg-two-img-m {
  width: 100vw;
  height: auto;
  background-image: url('@/assets/home/aboutus/twobg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.about-bg-two-img-m-title {
  border-radius: 50px 50px 50px 50px;
  border: 2px solid #083865;
  margin-top: 30px;
  padding: 10px 30px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #083865;
}
.about-bg-two-img-m-desc-one {
  font-weight: 400;
  color: #083865;
  width: 80%;
  font-size: 1.2rem;
  margin-top: 50px;
  text-align: center;
}
.about-bg-two-img-m-desc-two {
  width: 80%;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #083865;
  text-align: center;
}
.about-bg-three-img-m {
  background: linear-gradient(180deg, #f8fbfc 0%, #dcf5fd 100%);
  padding: 30px;
}
.about-bg-three-img-m-en-title {
  font-size: 1rem;
  color: #8d97a1;
}
.about-bg-three-img-m-title {
  font-weight: 500;
  font-size: 2rem;
  color: #333333;
  margin-top: 5px;
}
.about-bg-three-img-m-title-line {
  margin-top: 12px;
  width: 263px;
  height: 8px;
  background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
}
.about-bg-three-img-m-btm-text {
  color: #083865;
  width: 95%;
  margin: 0;
  padding-top: 30px;
  text-align: start;
  line-height: 20px;
  font-size: 1.2rem;
}
.about-bg-three-img-m-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 14%;
  margin-top: 17%;
}
.about-bg-three-img-m-cn-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: #333333;
}
.about-bg-three-img-m-en-name {
  font-weight: 400;
  font-size: 1rem;
  color: #666666;
  margin-top: 8px;
}
.about-bg-three-img-m-three-quan {
  background-image: url('@/assets/home/aboutus/mquan.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 373px;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
}
.about-bg-three-img-m-center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 33%;
  left: 40%;
}
.about-bg-three-img-m-cn-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: #265078;
}
.about-bg-three-img-m-en-name {
  font-weight: 400;
  font-size: 1rem;
  color: #417aaf;
  margin-top: 8px;
}
.about-bg-three-img-m-right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 14%;
  margin-top: 17%;
}
.about-bg-three-img-m-h-card {
  padding: 12px;
  background: #f0f6f8;
  display: flex;
  align-items: end;
  margin-bottom: 10px;
}

.about-bg-three-img-m-h-card .ant-image {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  height: 165px;
  margin-right: 10px;
}

.about-bg-three-img-m-baogao-view .ant-image {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  height: 165px;
  margin-right: 10px;
}

.about-bg-three-img-m-top-title {
  font-weight: 400;
  font-size: 1rem;
  color: #083865;
}
.about-bg-three-img-m-bt-ctnss {
  margin-left: 10px;
  margin-bottom: 20px;
}
.about-bg-three-img-m-sanimg {
  width: 8px;
}

.about-bg-three-img-m-zlihao {
  font-weight: 400;
  font-size: 0.7rem;
  color: #083865;
  margin-left: 8px;
}
.about-bg-three-img-m-h-card-two {
  padding: 10px;
  background: #f0f6f8;
  margin-top: 40px;
}
.about-bg-three-img-m-title-ctn {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.about-bg-three-img-m-en-title {
  font-weight: 500;
  font-size: 1rem;
  color: #8d97a1;
}
.about-bg-three-img-m-cn-title {
  font-weight: 400;
  font-size: 1.3rem;
  color: #083865;
}
.about-bg-three-img-m-baogao-view {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  overflow-x: auto;
}

.about-bg-four-m {
  padding: 30px;
  background-image: url('@/assets/home/Frame 38.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: column;
  height: 400px;
}
.about-bg-four-m-en-title {
  font-size: 0.8rem;
  color: #8d97a1;
}
.about-bg-four-m-title {
  font-weight: 500;
  font-size: 1.5rem;
  color: #333333;
  margin-top: 5px;
}
.about-bg-four-m-title-line {
  margin-top: 12px;
  width: 203px;
  height: 8px;
  background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
}
.about-bg-four-m-border-div {
  width: 70%;
  height: 30%;
  margin-top: 50px;
  margin-left: 15%;
  border-radius: 0px 0px 0px 0px;
  border: 3px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.about-bg-four-m-center-text {
  font-weight: 500;
  font-size: 1.1rem;
  color: #083865;
}
.about-bg-four-m-lefttop {
  position: absolute;
  top: -20px;
  left: -50px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 1.1rem;
  color: #083865;
  background: #ffffff;
  border-radius: 10px;
}
.about-bg-four-m-righttop {
  position: absolute;
  top: -20px;
  right: -50px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 1.1rem;
  color: #083865;
  background: #ffffff;
  border-radius: 10px;
}
.about-bg-four-m-leftbottom {
  position: absolute;
  bottom: -20px;
  left: -50px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 1.1rem;
  color: #083865;
  background: #ffffff;
  border-radius: 10px;
}
.about-bg-four-m-rightbottom {
  position: absolute;
  bottom: -20px;
  right: -50px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 1.1rem;
  color: #083865;
  background: #ffffff;
  border-radius: 10px;
}

.en-text {
  position: absolute;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 16.5vh;
  margin-left: 6vw;
}
.imgs-text {
  position: absolute;
  font-weight: 500;
  font-size: 2.3rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 10vh;
  margin-left: 6vw;
}

.m-wrapper {
  --photo-width: 300px;
  --photo-height: 200px;
  --photo-count: 5; /* photo数量 */
  --duration: 30s;
  margin-inline: auto;
  position: relative;
  padding-top: 40px;
  height: calc(var(--photo-height) + 20px);
  overflow: hidden;
  /* 模糊遮挡效果 */
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0)
  );
}
.m-item:hover {
  transform: scale(1.2);
  transition: 0.5s;
  box-sizing: 7px 11px 12px #f5b7b7;
}
.m-item {
  width: var(--photo-width);
  height: var(--photo-height);
  position: absolute;
  left: calc(var(--photo-width) * var(--photo-count) - 200px);
  animation-name: scrollLeft;
  animation-duration: var(--duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  /* 动态计算出每个photo的动画延时 */
  animation-delay: calc(
    var(--duration) / var(--photo-count) * (var(--photo-count) - var(--count)) *
      -1
  );
}

@keyframes scrollLeft {
  to {
    left: calc(var(--photo-width) * -1);
  }
}

.m-bttom-ctn {
  background: #1b243c;
  display: flex;
  flex-direction: column;
  padding: 40px 32px 15px 32px;

  /* align-items: center; */
  /* justify-content: center; */
}
.m-bttom-ctn div {
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 8px;
}
.m-img-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  margin-right: 30px;
}
.m-img-ctn .imgStyle {
  width: 100px;
  height: 100px;
}
.m-img-ctn .imgText {
  margin-top: 20px;
}

.ant-carousel .cc .slick-dots li.slick-active button {
  background-color: #1549d0;
  width: 32px;
  height: 12px;
  border-radius: 41px;
}
.ant-carousel .cc .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #92969f;
}
.ant-carousel .cc .slick-dots li {
  margin-right: 24px;
}
.ant-carousel .cc .slick-dots {
  margin-bottom: 10vh;
}
.carousel-img {
  width: 100vw;
  height: 90vh;
  background-image: url('@/assets/home/banner1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 80%;
  position: relative;
}
.left-ctn {
  position: absolute;
  top: 0;
  margin-top: 30vh;
  margin-left: 6vw;
  .title {
    font-weight: 500;
    font-size: 3rem;
    color: #083865;
    margin-bottom: 10px;
  }
  .ctn {
    font-weight: 400;
    font-size: 1.2rem;
    color: #083865;
    margin-bottom: 100px;
  }
  .btn {
    width: 160px;
    height: 60px;
    background: #ffffff;
    border-radius: 100px;
    font-weight: bold;
    font-size: 1rem;
    color: #083865;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.home-one {
  position: relative;
}
.home-two {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: bold;
    font-size: 2rem;
    color: #333333;
    margin-top: 8vh;
  }
  .look-all {
    font-weight: 400;
    font-size: 1rem;
    color: #1549d0;
    margin-top: 3vh;
    cursor: pointer;
  }
  .flex-row {
    display: flex;
    margin-top: 10vh;
    /* align-items: center; */
    .left-card {
      width: 18vw;
      height: 49.2vh;
      background: linear-gradient(180deg, #f2f8fa 0%, #edf9fc 100%);
      box-shadow: 0px 2px 3px 0px rgba(224, 231, 235, 0.2);
      border-radius: 12px;
      margin-right: 20px;
      position: relative;
      transition: transform 0.3s, box-shadow 0.3s;
      /* padding: 32px; */
      .card-title {
        font-weight: 500;
        font-size: 1.5rem;
        color: #333333;
        margin-top: 4vh;
        margin-left: 2vw;
      }
      .card-ctn {
        font-weight: 400;
        font-size: 1rem;
        color: #666666;
        margin: 1.5vh 2vw;
      }
      .card-img {
        width: 204px;
        height: 144px;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 5vh;
        margin-right: 1vw;
        transition: transform 0.1s;
      }
    }

    .left-card:hover {
      transform: scale(1.1);
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    }
    .left-card:hover .card-img {
      transform: scale(1.5); /* 图片悬停时的动态效果 */
    }

    .left-right {
      width: 20vw;
      height: 20vh;
      background: linear-gradient(180deg, #f2f8fa 0%, #edf9fc 100%);
      box-shadow: 0px 2px 3px 0px rgba(224, 231, 235, 0.2);
      border-radius: 12px;
      margin-bottom: 20px;
      margin-right: 20px;
      position: relative;
      padding: 3.5vh 0 0 2vw;
      transition: transform 0.3s, box-shadow 0.3s;
      .card-title {
        font-weight: 500;
        font-size: 1.5rem;
        color: #333333;
      }
      .card-ctn {
        font-weight: 400;
        font-size: 1rem;
        color: #666666;
        margin-top: 1.5vh;
        margin-right: 2vw;
      }
      .card-img {
        width: 90px;
        height: 90px;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 1vh;
        margin-right: 1vw;
        transition: transform 0.1s;
      }
    }
    .left-right:hover {
      transform: scale(1.1);
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    }
    .left-right:hover .card-img {
      transform: scale(1.5); /* 图片悬停时的动态效果 */
    }
  }
}
.bt-ctn-home {
  width: 100vw;
  padding: 82px;
  background-image: url('@/assets/home/home-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .en-title {
    font-size: 1.2rem;
    color: #ffffff;
  }
  .cn-title {
    font-weight: 500;
    font-size: 2.2rem;
    color: #ffffff;
    margin-top: 15px;
  }
  .desc {
    font-weight: 400;
    font-size: 1.1rem;
    color: #ffffff;
    width: 600px;
    text-align: center;
    margin-top: 44px;
  }
  .line-view {
    background-image: url('@/assets/home/line.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 800px;
    width: 100%;
    margin-top: 60px;
    position: relative;
    .leftone {
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 20%;
      margin-top: 0.8%;
    }
    .rightone {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 20%;
      margin-top: 0.8%;
    }
    .bottomone {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: 26.5%;
      margin-bottom: 17.5%;
    }
    .bottomtwo {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-right: 26.5%;
      margin-bottom: 17.5%;
    }
    .one {
      font-weight: 500;
      font-size: 1.4rem;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .three {
      font-weight: 500;
      font-size: 1.1rem;
      color: #ffffff;
      margin-top: 60px;
    }
  }
}

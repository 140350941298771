.bttom-ctn {
  height: 30vh;
  width: 100vw;
  background: #1b243c;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;

  div {
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 16px;
    span {
      color: #ffffff;
      margin-left: 21px;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  .img-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    margin-right: 70px;
    .imgStyle {
      width: 114px;
      height: 114px;
    }
    .imgText {
      font-weight: 500;
      font-size: 1rem;
      margin-top: 12px;
      margin-bottom: 0;
    }
  }
}

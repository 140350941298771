.about-bg-top-img {
  width: 100vw;
  height: 550px;
  background-image: url('@/assets/home/aboutus/aboutus-top.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.about-bg-two-img {
  width: 100vw;
  height: 500px;
  background-image: url('@/assets/home/aboutus/twobg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  .title {
    border-radius: 50px 50px 50px 50px;
    border: 2px solid #083865;
    padding: 15px 37px;
    font-weight: bold;
    font-size: 1.5rem;
    color: #083865;
    margin-top: 85px;
  }
  .desc-one {
    font-weight: 400;
    font-size: 1rem;
    color: #083865;
    width: 60%;
    margin-top: 50px;
    text-align: center;
  }
  .desc-two {
    font-weight: 400;
    font-size: 1rem;
    color: #083865;
    width: 50%;
    margin-top: 50px;
    text-align: center;
  }
}
.about-bg-three-img {
  background: linear-gradient(180deg, #f8fbfc 0%, #dcf5fd 100%);
  padding: 82px 6vw;
  .en-title {
    font-size: 1rem;
    color: #8d97a1;
  }
  .title {
    font-weight: 500;
    font-size: 2rem;
    color: #333333;
    margin-top: 5px;
  }
  .title-line {
    margin-top: 12px;
    width: 263px;
    height: 8px;
    background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
  }
  .btm-text {
    font-weight: 400;
    font-size: 1rem;
    color: #083865;
    text-align: center;
    margin-top: 50px;
    width: 70%;
    margin-left: 15%;
  }
  .three-quan {
    background-image: url('@/assets/home/aboutus/quan.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* background-position: center; */
    position: relative;
    height: 708px;
    margin-top: 100px;
    .left-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 10%;
      margin-left: 10%;
      .cn-name {
        font-weight: bold;
        font-size: 1.8rem;
        color: #333333;
      }
      .en-name {
        font-weight: 400;
        font-size: 1.2rem;
        color: #666666;
        margin-top: 8px;
      }
    }
    .center-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10%;
      .cn-name {
        font-weight: bold;
        font-size: 1.8rem;
        color: #265078;
      }
      .en-name {
        font-weight: 400;
        font-size: 1.2rem;
        color: #417aaf;
        margin-top: 8px;
      }
    }
    .right-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 10%;
      margin-right: 10%;
      .cn-name {
        font-weight: bold;
        font-size: 1.8rem;
        color: #333333;
      }
      .en-name {
        font-weight: 400;
        font-size: 1.2rem;
        color: #666666;
        margin-top: 8px;
      }
    }
  }
  .h-card {
    padding: 40px;
    width: 35%;
    background: #f0f6f8;
    display: flex;
    align-items: end;
    .top-title {
      font-weight: 400;
      font-size: 1rem;
      color: #083865;
    }
    .bt-ctnss {
      margin-left: 55px;
      margin-bottom: 20px;
    }
    .sanimg {
      width: 10px;
    }

    .zlihao {
      font-weight: 400;
      font-size: 0.7rem;
      color: #083865;
      margin-left: 15px;
    }
  }
  .h-card-two {
    padding: 40px;
    background: #f0f6f8;
    margin-top: 40px;
    .title-ctn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .en-title {
        font-weight: 500;
        font-size: 1rem;
        color: #8d97a1;
      }
      .cn-title {
        font-weight: 400;
        font-size: 1.3rem;
        color: #083865;
      }
    }
    .baogao-view {
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
      overflow-x: auto;
      .ant-image {
        margin-right: 30px;
        min-width: 200px;
      }
    }
  }
}
.about-bg-four {
  padding: 104px 0 104px 104px;
  display: flex;
  .leftFour {
    padding: 60px;
    width: 50%;
    background: #f3fafc;
    .en-title {
      font-size: 0.8rem;
      color: #8d97a1;
    }
    .title {
      font-weight: 500;
      font-size: 1.5rem;
      color: #333333;
      margin-top: 5px;
    }
    .title-line {
      margin-top: 12px;
      width: 203px;
      height: 8px;
      background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
    }
    .border-div {
      width: 80%;
      height: 60%;
      margin-top: 80px;
      margin-left: 10%;
      border-radius: 0px 0px 0px 0px;
      border: 3px solid #dcf0f5;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center-text {
        font-weight: 500;
        font-size: 1.8rem;
        color: #083865;
      }
      .lefttop {
        position: absolute;
        top: -30px;
        left: -70px;
        padding: 15px 27px;
        font-weight: 400;
        font-size: 1.2rem;
        color: #083865;
        background: #dcf0f5;
        border-radius: 10px;
      }
      .righttop {
        position: absolute;
        top: -30px;
        right: -70px;
        padding: 15px 27px;
        font-weight: 400;
        font-size: 1.2rem;
        color: #083865;
        background: #dcf0f5;
        border-radius: 10px;
      }
      .leftbottom {
        position: absolute;
        bottom: -30px;
        left: -70px;
        padding: 15px 27px;
        font-weight: 400;
        font-size: 1.2rem;
        color: #083865;
        background: #dcf0f5;
        border-radius: 10px;
      }
      .rightbottom {
        position: absolute;
        bottom: -30px;
        right: -70px;
        padding: 15px 27px;
        font-weight: 400;
        font-size: 1.2rem;
        color: #083865;
        background: #dcf0f5;
        border-radius: 10px;
      }
    }
  }
  .endimg {
    width: 50%;
  }
}
.en-text {
  position: absolute;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 16.5vh;
  margin-left: 6vw;
}
.imgs-text {
  position: absolute;
  font-weight: 500;
  font-size: 2.3rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 10vh;
  margin-left: 6vw;
}
.home-two-about {
  width: 100vw;
  height: 617px;
  background-image: url('@/assets/home/info-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  .img-ctn {
    display: flex;
    padding: 7.5vh 6vw;
  }
  .title {
    font-weight: 500;
    font-size: 2rem;
    color: #333333;
    min-width: 10vw;
  }
  .right-ctn {
    margin-left: 20vw;
    margin-top: 7vh;
  }
  .ctn {
    font-weight: 400;
    font-size: 1rem;
    color: #333333;
    margin-top: 6vh;
  }
}

.about-three-about {
  width: 100vw;
  height: 609px;
  background: #f8fbfc;
  box-shadow: 0px 2px 3px 0px rgba(224, 231, 235, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .img-ctn {
    display: flex;
    padding: 7.5vh 6vw;
    position: absolute;
    top: 0;
    left: 0;
    .title {
      font-weight: 500;
      font-size: 2rem;
      color: #333333;
      min-width: 10vw;
    }
  }
  .logoimg {
    width: 620px;
    height: 165px;
    margin-top: 10vh;
  }
  .logo-text {
    font-weight: 500;
    font-size: 1.6rem;
    color: #193084;
    margin-top: 48px;
  }
}

/* .ant-image {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 165px;
  margin-right: 10px;
} */

.wrapper {
  --photo-width: 500px;
  --photo-height: 350px;
  --photo-count: 5; /* photo数量 */
  --duration: 30s;
  margin-inline: auto;
  position: relative;
  padding-top: 80px;
  height: calc(var(--photo-height) + 50px);
  /* margin-top: 3%; */
  overflow: hidden;
  /* 模糊遮挡效果 */
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 95%,
    rgba(0, 0, 0, 0)
  );
}
.item:hover {
  transform: scale(1.2);
  transition: 0.5s;
  box-sizing: 7px 11px 12px #f5b7b7;
}
.item {
  width: var(--photo-width);
  height: var(--photo-height);
  position: absolute;
  left: calc(var(--photo-width) * var(--photo-count) - 200px);
  animation-name: scrollLeft;
  animation-duration: var(--duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  /* 动态计算出每个photo的动画延时 */
  animation-delay: calc(
    var(--duration) / var(--photo-count) * (var(--photo-count) - var(--count)) *
      -1
  );
}

@keyframes scrollLeft {
  to {
    left: calc(var(--photo-width) * -1);
  }
}

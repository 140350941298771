.home-one {
  position: relative;
}
.bg-top-img {
  width: 100vw;
  height: 90vh;
  background-image: url('@/assets/home/result1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
/* .img-text {
  position: absolute;
  font-weight: 500;
  font-size: 2.3rem;
  color: #ffffff;
  bottom: 0;
  margin-bottom: 15vh;
  margin-left: 6vw;
} */
.top-center-text {
  position: absolute;
  top: 0;
  margin-top: 38vh;
  margin-left: 6vw;
  font-size: 1.1rem;
  color: #ffffff;
  width: 50%;
  line-height: 35px;
}
.top-img-text {
  position: absolute;
  top: 0;
  margin-top: 20vh;
  margin-left: 6vw;
}
.en-title {
  font-weight: 400;
  font-size: 1.1rem;
  color: #ffffff;
}
.cn-title {
  font-weight: bold;
  font-size: 2.5rem;
  color: #ffffff;
  margin-top: 8px;
}
.zlh {
  position: absolute;
  bottom: 0;
  margin-bottom: 15vh;
  margin-left: 6vw;
  font-size: 1rem;
  color: #ffffff;
}
.home-two-result {
  width: 100vw;
  height: 100vh;
  background-image: url('@/assets/home/canshu2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .right-ctn-four {
    position: absolute;
    right: 0;
    margin-right: 5vw;
    margin-top: 75px;
    width: 50%;
    .en-title {
      font-size: 1rem;
      color: #8d97a1;
    }
    .title {
      font-weight: 500;
      font-size: 2rem;
      color: #333333;
      margin-top: 5px;
    }
    .title-line {
      margin-top: 12px;
      width: 263px;
      height: 8px;
      background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
    }
    .ctn {
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      margin-top: 4vh;
      width: 100%;
      margin-bottom: 30px;
      line-height: 30px;
    }
    .disflex {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
    .result-js {
      background-image: url('@/assets/home/CNAS@2x.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 436px;
      height: 280px;
    }
    .right-card {
      width: 604px;
      height: 149px;
      background: #ffffff;
      box-shadow: 0px 4px 6px 0px rgba(229, 240, 243, 0.3);
      border-radius: 4px;
      margin-top: 159px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .bottom-text {
    position: absolute;
    bottom: 0;
    padding: 0 6vw;
    margin-bottom: 6vh;
    .one-title {
      font-weight: bold;
      font-size: 1.5rem;
      color: #083865;
    }
    .content {
      font-weight: 400;
      font-size: 1rem;
      color: #083865;
      margin-top: 20px;
      line-height: 30px;
    }
  }
}
.home-three-result {
  /* width: 100%; */
  height: 190vh;
  position: relative;
  background-image: url('@/assets/home/领域特点背景@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 82px 6vw;
  .leftBtn {
    position: absolute;
    left: 0;
    top: 0;
    width: 72px;
    height: 72px;
    margin-top: 50vh;
    margin-left: 4vw;
    z-index: 1;
    cursor: pointer;
  }
  .rightBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: 72px;
    height: 72px;
    margin-top: 50vh;
    margin-right: 4vw;
    cursor: pointer;
  }
  .en-title {
    font-size: 1rem;
    color: #8d97a1;
  }
  .title {
    font-weight: 500;
    font-size: 2rem;
    color: #333333;
    margin-top: 5px;
  }
  .title-line {
    margin-top: 12px;
    width: 263px;
    height: 8px;
    background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
  }
  .accordion {
    display: flex;
    width: 100%;
    margin-top: 50px;
  }

  .accordion-item {
    position: relative;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .default-image {
    display: block;
    width: 100%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .d-title-ctn {
      padding-top: 23vh;
      padding-left: 2vw;
      .d-enTitle {
        font-weight: 500;
        font-size: 1rem;
        color: #ffffff;
      }
      .d-cnTitle {
        font-weight: 500;
        font-size: 1.5rem;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }

  .hover-image {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .ctn-desc {
      font-weight: 500;
      font-size: 1.1rem;
      color: #ffffff;
      margin: 40px;
      line-height: 30px;
    }
    .title-ctn {
      padding-top: 16vh;
      padding-left: 5vw;
      .enTitle {
        font-weight: 500;
        font-size: 1rem;
        color: #ffffff;
      }
      .cnTitle {
        font-weight: 500;
        font-size: 1.5rem;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }

  /* 默认第一个项展开并显示 hover-image */
  .accordion-item.active {
    min-width: 35%;
  }

  /* 展开状态下隐藏 default-image，显示 hover-image */
  .accordion-item.active .default-image {
    display: none;
  }

  .accordion-item.active .hover-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  /* 非悬停项收缩 */
  .accordion-item:not(.active) {
    min-width: 15%;
  }

  .smallIcon {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 80px;
    margin-bottom: 25px;
    margin-left: 40px;
  }

  .cp-view {
    display: flex;
    margin-top: 44px;
    .cp-left {
      display: flex;
      margin-top: 80px;
      margin-left: 50px;
      min-width: 15vw;
      .leftLine {
        width: 0px;
        /* min-height: 90%;
        max-height: 90%; */
        border-radius: 5px;
        border: 4px solid #cce8fe;
      }
      .rightText {
        margin-top: 15px;
        .textStyle {
          font-weight: 400;
          font-size: 1.2rem;
          height: 60px;
          color: #b0b9be;
          cursor: pointer;
          margin-left: 42px;
          display: flex;
          align-items: center;
        }
        .check {
          background-image: url('@/assets/home/selectchanpin.png');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin-left: -10px;
        }
      }
    }
    .rightBottomText {
      padding-left: 10vw;
      margin-top: 3vh;
      .title {
        font-weight: bold;
        font-size: 2rem;
        color: #083865;
      }
      .desc {
        margin-top: 20px;
        font-weight: 400;
        font-size: 1.3rem;
        color: #666666;
        line-height: 30px;
        width: 60vw;
      }
    }
    .rightImg {
      width: 50vw;
      margin-left: 12vw;
      margin-top: -10vh;
    }
  }

  .jm-card {
    display: flex;
    align-items: center;
    background-color: #f0fafd;
    padding: 30px 50px;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    .title {
      font-weight: bold;
      font-size: 1.4rem;
      color: #083865;
    }
    .desc {
      font-weight: 400;
      font-size: 1rem;
      color: #666666;
      margin-top: 12px;
      width: 80%;
      line-height: 30px;
    }
    .imgstyle {
      width: 50px;
    }
  }

  .daping {
    align-items: center;
    justify-content: center;
    padding: 46px 60px;
    box-sizing: border-box;
    background-color: #ffffff;
    .dapingImg {
      width: 80%;
    }
    .title {
      font-weight: bold;
      font-size: 2rem;
      color: #083865;
      margin-top: 30px;
    }
    .desc {
      margin-top: 20px;
      font-weight: 400;
      font-size: 1.3rem;
      color: #666666;
      line-height: 30px;
      width: 60vw;
    }
  }
}

.home-four-result {
  /* height: 70vh; */
  position: relative;
  background-image: url('@/assets/home/result/fourbg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 82px 6vw;

  .en-title {
    font-size: 1rem;
    color: #8d97a1;
  }
  .title {
    font-weight: 500;
    font-size: 2rem;
    color: #333333;
    margin-top: 5px;
  }
  .title-line {
    margin-top: 12px;
    width: 263px;
    height: 8px;
    background: linear-gradient(90deg, #1549d0 0%, rgba(21, 73, 208, 0) 100%);
  }
  .bannerImg {
    width: 60% !important;
    margin: 30px 20%;
  }

  .ant-carousel .slick-prev {
    width: 72px;
    height: 72px;
  }
  .ant-carousel .slick-next {
    width: 72px;
    height: 72px;
  }
}

.mTopTabs {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 15vh;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mTopTabs .top-flex {
  display: flex;
  align-items: center;
  margin-top: 3vh;
}

.mTopTabs .top-flex .top-logo {
  width: 38px;
  height: 27px;
  margin-left: 4vw;
}

.mTopTabs .top-flex .logo-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: #036eb8;
  margin-left: 14px;
}

.mTopTabs .active {
  color: #1549d0;
  font-weight: bold;
}

.mTopTabs .divBlack {
  color: #000000;
}

.mTopTabs div {
  margin-right: 3vw;
  cursor: pointer;
  color: #ffffff;
}
.menu-div {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 15vh;
  z-index: 1;
  width: 100%;
  transition: opacity 0.2s ease-in-out; /* 添加 transition 属性 */
  opacity: 1; /* 初始时设置透明度为 1 */
  display: block;
}
.menu-div-hidden {
  opacity: 0; /* 隐藏时将透明度设置为 0 */
  pointer-events: none; /* 隐藏时禁用交互 */
}
.menu-div .active {
  padding-top: 24px;
  padding-left: 50px;
  padding-bottom: 24px;
  font-weight: 400;
  font-size: 1.2rem;
  color: #333333;
}

.page-all {
  height: 100vh;
  overflow: hidden;
}
.dow-page {
  height: 100%;
  width: 100vw;
  background-image: url('@/assets/m/downloadbg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 13vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dow-page .logoimg {
  height: 64px;
  width: 64px;
  border-radius: 10px;
}
.logoname {
  font-weight: bold;
  font-size: 1.6rem;
  color: #333333;
  margin-top: 24px;
}
.logotips {
  font-weight: 400;
  font-size: 1rem;
  color: #666666;
  margin-top: 10px;
}

.btm-dowbtn {
  margin-top: 49vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 179px;
  height: 44px;
  background: linear-gradient(134deg, #61c6ff 0%, #3278ff 100%), #1549d0;
  border-radius: 8px 8px 8px 8px;
}
.btm-dowbtn img {
  width: 24px;
  height: 24px;
}
.btm-dowbtn div {
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  margin-left: 10px;
}
